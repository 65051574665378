<template>
  <div class="transaction">
    <header-page>
      <router-link :to="{ name: 'stock.index' }">
        <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px">Pengaturan Stok</span>
      </router-link>
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-700 size14">Stok Opname</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button v-for="(item, index) in listFilter" :id="`button--filter${index}`" :key="`${item}-${index}`"
          class="filter--button" :class="{ active: item == filter }" @click="
            (filter = item),
            (comp =
              item == 'Stok Opname' ? 'TableStockOpname' : 'TableApproval')
            ">
          {{ item }}
          {{
            item == "Persetujuan"
              ? `(${$store.state.stockOpname.totalNotApproved || 0})`
              : ""
          }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export">
        <FilterButton v-if="filter == 'Stok Opname'" @filterBase="getFilterBase" :isOpname="true" />

        <b-button v-if="
          checkPermission('add stock opname') && comp == 'TableStockOpname'
        " variant="outline-secondary py-1 bg-secondary text-white" @click="goToStokOpname">
          <span>Tambah Stok Opname</span>
        </b-button>
      </div>
    </div>

    <keep-alive>
      <TableStockOpname v-if="filter == 'Stok Opname'" :get-data="getData" :get-data-approval="getDataApproval"
        :get-detail="getDetail" :result="result" :loading="isLoading" @filter="getFilterOpname" />
      <TableApproval v-if="filter == 'Persetujuan'" :get-data="getData" :get-detail="getDetail"
        :get-data-approval="getDataApproval" :result-approval="resultApproval" :loading="isLoading" />
      <!-- <component
        :is="comp"
        :result="result"
        :result-approval="resultApproval"
        :get-data="getData"
        :get-data-approval="getData"
      /> -->
    </keep-alive>
    <ModalAddStockOpname :get-data="getData" :get-data-approval="getDataApproval" />
    <ModalDetailStockOpname :get-detail="getDetail" :detail-result="detailResult" :get-data="getData"
      :get-data-approval="getDataApproval" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { BButton, BButtonGroup, VBToggle } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import ModalAddStockOpname from "@/components/Stock/Opname/ModalAddStockOpname.vue";
import ModalDetailStockOpname from "@/components/Stock/Opname/ModalDetailStockOpname.vue";
import HeaderPage from "@/components/HeaderPage.vue";
import TableStockOpname from "@/components/Stock/Opname/TableStockOpname.vue";
import TableApproval from "@/components/Stock/Opname/TableApproval.vue";
import FilterButton from "@/components/BaseFilter.vue";

export default {
  title() {
    return "Stok Opname";
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    TableStockOpname,
    TableApproval,
    ModalAddStockOpname,
    ModalDetailStockOpname,
    FilterButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      listFilter: ["Stok Opname", "Persetujuan"],
      filter: "Stok Opname",
      comp: "TableStockOpname",
      result: {},
      detailResult: {},
      resultApproval: {},
      filterOpname: {},
      filters: {},
      id: null,
      isLoading: false,
    };
  },
  watch: {
    filters: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
    filterOpname: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
    // detailResult(value) {
    //   this.getDetail(value)
    // },
  },
  mounted() {
    const userPermission = localStorage.getItem("userPermission");
    if (
      userPermission &&
      !userPermission.includes("approve/reject stock opname")
    ) {
      this.listFilter = ["Stok Opname"];
      this.filter = "Stok Opname";
      this.comp = "TableStockOpname";
      this.getData();
    } else if (
      userPermission &&
      !userPermission.includes("list stock opname")
    ) {
      this.listFilter = ["Persetujuan"];
      this.filter = "Persetujuan";
      this.comp = "TableApproval";
      this.getDataApproval();
    } else {
      this.getData();
      this.getDataApproval();
    }

    if (this.$route.query.q === "approval") {
      this.filter = "Persetujuan";
    }
    if (this.$route.query.ctaId) {
      this.$bvModal.show("modal-add-stock-opname");
    }
  },
  methods: {
    goToStokOpname() {
      this.$store.commit("stockOpname/setIsDetail", false);
      this.$store.commit("stockOpname/setDetailItem", null);
      this.$bvModal.show("modal-add-stock-opname");
    },
    getFilterOpname(value) {
      this.filterOpname = value;
    },
    getFilterBase(value) {
      this.filters = value;
    },
    getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.per_page = this.filterOpname.per_page;
      queryParams.page = page;
      this.$store
        .dispatch("stockOpname/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.result = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.isLoading = false;
        });
    },
    getDataApproval(page = 1) {
      this.$store
        .dispatch("stockOpname/getData", {
          uuid: "",
          params: {
            status: "pending",
            page,
          },
        })
        .then((result) => {
          this.resultApproval = result.data.data;
          this.$store.commit(
            "stockOpname/setTotalNotApproved",
            result.data.data.total
          );
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getDetail(id) {
      this.id = id;
      this.$store
        .dispatch("stockOpname/getData", {
          uuid: id || "",
          params: "",
        })
        .then((result) => {
          this.detailResult = result.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.transaction {
  position: relative;

  .vgt-table.bordered {
    thead {
      th:last-child {
        text-align: center;
      }
    }

    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
    }

    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #6b6c7e;
      font-weight: 700;
    }
  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }

    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item~.page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item~.page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #e4e5ec;

    .dropdown {
      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      .search__input {
        margin-right: 8px;

        .input-group {
          width: 23.451vw;
          height: 48px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

          input {
            height: 48px;
            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          .input-group-append {
            .input-group-text {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: none;
            }
          }
        }
      }

      #search {
        width: 278px;
        height: 48px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      }

      .btn.bg-white {
        border: none !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        img {
          margin-right: 8px;
        }

        span {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -48%;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
